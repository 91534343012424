<template>
<!-- 签署流程模板 -->
  <div class="container">
    <el-alert
    title="模板是适用于合同中某些内容需要针对不同的人填写不同值的情况，比如：甲乙方姓名、身份证、地址等。需要在合同上设置填写控件生成模板，再通过控件填写不同的内容形成最终签署的合同。"
    type="warning"
    :closable="false">
  </el-alert>
    <el-form :inline="true" class="m-t10">
      <el-form-item>
        <el-button type="primary" @click="openCreate">创建流程模板</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #createTime="{ row }">
        <div>
          {{ new Date(row.createTime).Format("yyyy-MM-dd hh:mm:ss") }}
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-switch
            v-model="row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            active-text="启用"
            inactive-text="停用"
            @change="statusChange(row)"
          >
          </el-switch>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="editTemplate(row)">编辑</el-button>
          <el-button type="success" size="small" @click="toCreate(row)"
            >发起签署</el-button
          >
        </div>
      </template>
    </auto-table>

    <el-dialog
      :close-on-click-modal="false"
      title="新增模板"
      :visible.sync="show"
      width="30%"
    >
      <div>
        <eFileUpload ref="fu" @success="fileUploadSuccess"></eFileUpload>
        <el-form>
          <el-form-item label="模板名称" required>
            <el-input v-model="form.name" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="getUploadUrl">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import eFileUpload from "../../../components/upload/eFileUpload.vue";

var timer = "";
export default {
  components: {
    eFileUpload,
  },
  data() {
    return {
      form: {
        name: "",
        type: 0,
      },
      show: false,
      DataList: [],
      Option: [
        { name: "模板名称", value: "signTemplateName" },
        { name: "状态", value: "status", type: "custom" },
        { name: "创建时间", value: "createTime", type: "custom" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",

      fileData: "",
      fileUrl: "",
      upload: {
        fileId: "",
        path: "",
      },

      loading: "",
    };
  },

  mounted() {
    this.getList(1);
  },
  destroyed() {
    clearInterval(timer);
  },
  methods: {
    statusChange(row){
        console.log(row);
        if(row.status == 1){
            this.$get("/user/esignTemp/templateOpen", {signTemplateId : row.signTemplateId}).then((res) => {
                //  this.getList(1);
            })
        }else{
            this.$get("/user/esignTemp/templateClose", {signTemplateId : row.signTemplateId}).then((res) => {
                //  this.getList(1);
            })
        }
    },
    toCreate(row){
        this.$store.commit('setSignTemplate',row)
        this.$router.push({
            name:'createSign',
            query:{
                signTemplateId:row.signTemplateId
            }
        })
    },
    //编辑
    editTemplate(row) {

      this.$get("/user/esignTemp/templateClose", {signTemplateId : row.signTemplateId}).then((res) => {
          let data = {
        signTemplateId : row.signTemplateId,
        redirectUrl: this.$href+"#/main/esignTemplateList",
      };
      this.$post("/user/esignTemp/getTemplateEditUrl", data).then((res) => {
        window.location.href = res.data.data.signTemplateEditUrl;
      });
      });  
     
    },
    //上传文件
    getUploadUrl() {
      if (!this.form.name) {
        this.$message("请填写模板名称");
        return;
      }
      let data = {
        fileName: this.fileData.name,
        fileSize: this.fileData.size,
        filePath: this.fileUrl,
      };
      this.loading = this.$loading({
        lock: true,
        text: "模板文件上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$get("user/esign/getUploadUrl", data).then((res) => {
        let v = res.data.data;
        this.upload.fileId = v.fileId;
        this.upload.path = v.fileUploadUrl;
        timer = setInterval(() => {
          this.$get("user/esign/getFileStatus", {
            id: this.upload.fileId,
          }).then((res) => {
            if (
              res.data.data.fileStatus == 2 ||
              res.data.data.fileStatus == 5
            ) {
              clearInterval(timer);
              this.createsignTemplate();
              this.loading.close();
            }
          });
        }, 1000);
      });
    },
    createsignTemplate() {
      let data = {
        docTemplateName: this.form.name,
        docTemplateType: this.form.type,
        fileId: this.upload.fileId,
        redirectUrl: this.$href+"#/main/esignTemplateList",
      };
      this.$post("/user/esignTemp/getTemplateCreateUrl", data).then((res) => {
        window.location.href = res.data.data.docTemplateCreateUrl;
      });
    },
    fileUploadSuccess(v) {
      this.fileData = v.fileData;
      this.fileUrl = v.fileUrl;
    },
    openCreate() {
      let data = {
        redirectUrl: this.$href+"#/main/signTemplateList",
      };
      this.$post("/user/esignTemp/getTemplateCreateUrl", data).then((res) => {
        window.location.href = res.data.data.signTemplateCreateUrl;
      });
    },
    //列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/esignTemp/queryTemplateList",
        params: {
          currentPage: page,
          pageSize: 10,
        },
      }).then((res) => {
        this.DataList = res.data.data.signTemplates;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>