<template>
  <div class="up-wrap">
    <el-upload
      class="upload-demo"
      :limit="1"
      :action="domain"
      :on-success="uploadSuccess"
      :data="QiNiYunL"
      :before-upload="before"
      :on-change="changeFile"
      accept=".pdf"
      :file-list="fileList"
    >
      <el-button size="small" type="primary">点击上传文件</el-button>
      <div class="el-upload__tip" slot="tip">
        只能上传pdf文件，且不超过20M；文件名中不能含有特殊字符/\*:">?等。
      </div>
      <div v-if="loading">
        文件上传中
        <i class="el-icon-loading"></i>
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
 props:{
    list:{
        type:Array,
        default:()=>{return []}
    }
 },
  data() {
    return {
      domain: "http://up-z0.qiniup.com/",
      upload_qiniu_addr: "https://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
      fileUrl: "",
      fileList: [],
      fileData: "",
      loading:false
    };
  },
  watch:{
    list:{
        handler:function(nv){
            this.fileList = nv
        },
        immediate:true
    }
  },

  mounted() {},

  methods: {
    uploadSuccess(file) {
      this.fileList = [{ name: this.fileData.name }]
      this.fileUrl = this.upload_qiniu_addr + file.key;
        this.loading = false
      this.$emit("success", {
            fileData: this.fileData,
            fileUrl: this.fileUrl
      });
    },
    async before() {
      console.log(this.fileData.name);
     if(!/[~!@#$%^&*_+-=\[\]{}]/.test(this.fileData.name)){
        this.$message("文件名包含特殊字符")
        return Promise.reject()
    }
      let res = await this.gettoken();
      this.loading = true
      return res;
    },
    gettoken() {
      return new Promise((resolve, reject) => {
        let _this = this;
        let data = {
          key: new Date().getTime() + this.fileData.name,
        };
        _this
          .$axios({
            method: "post",
            url: "https://www.curiousmore.com/qmore/push/upload/getToken",
            data: data,
          })
          .then((res) => {
            _this.QiNiYunL.token = res.data.data;
            _this.QiNiYunL.key = data.key;
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            reject(false);
          });
      });
    },
    changeFile(file) {
      this.fileData = file.raw;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-upload-list__item {
  transition: none !important;
}
.up-wrap{
    padding: 10px;
    border: 1px dashed rgb(127, 182, 233);
    background-color: #f7f7f7;
    border-radius: 4px;
    margin-bottom: 20px;
}
</style>